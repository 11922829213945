import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import './Styles/main.scss';

import MainView from './Views/Main';

if (process.env.NODE_ENV === 'development') {
  global.api = 'http://ru-radar.local/';
}
else {
  global.api = 'https://ru-radar.ru/';
}

if (global.Notification) {
  Notification.requestPermission().then((result) => {
    if (result === 'granted') {
      navigator.serviceWorker.ready.then(function(registration) {
        registration.showNotification("Поиск дронов рядом...", {
          body: 'Мы постараемся сообщить о чрезвычайных событиях рядом, если они произойдут.',
          icon: '/assets/icons/icon-512x512.png',
          badge: '/assets/icons/badge.png',
          tag: 'rr-find'
        });
      });
    }
  });
}

navigator.serviceWorker.register('sw.js');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MainView />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
